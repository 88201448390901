/**
 * Foundation for Sites by ZURB
 * Version 6.3.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0;
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type=button],
[type=reset],
[type=submit] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em";
}

html {
  box-sizing: border-box;
  font-size: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 3px;
}

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 3px;
}

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important;
}

button {
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  border-radius: 3px;
  background: transparent;
  line-height: 1;
}

[data-whatinput=mouse] button {
  outline: 0;
}

pre {
  overflow: auto;
}

.is-visible {
  display: block !important;
}

.is-hidden {
  display: none !important;
}

.row {
  max-width: 62.5rem;
  margin-right: auto;
  margin-left: auto;
}

.row::before, .row::after {
  display: table;
  content: " ";
}

.row::after {
  clear: both;
}

.row.collapse > .column, .row.collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}

.row .row {
  margin-right: -0.9375rem;
  margin-left: -0.9375rem;
}

@media print, screen and (min-width: 40em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
.row .row.collapse {
  margin-right: 0;
  margin-left: 0;
}

.row.expanded {
  max-width: none;
}

.row.expanded .row {
  margin-right: auto;
  margin-left: auto;
}

.row:not(.expanded) .row {
  max-width: none;
}

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
  float: right;
}

.column.end:last-child:last-child, .end.columns:last-child:last-child {
  float: left;
}

.column.row.row, .row.row.columns {
  float: none;
}

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.small-1 {
  width: 8.33333%;
}

.small-push-1 {
  position: relative;
  left: 8.33333%;
}

.small-pull-1 {
  position: relative;
  left: -8.33333%;
}

.small-offset-0 {
  margin-left: 0%;
}

.small-2 {
  width: 16.66667%;
}

.small-push-2 {
  position: relative;
  left: 16.66667%;
}

.small-pull-2 {
  position: relative;
  left: -16.66667%;
}

.small-offset-1 {
  margin-left: 8.33333%;
}

.small-3 {
  width: 25%;
}

.small-push-3 {
  position: relative;
  left: 25%;
}

.small-pull-3 {
  position: relative;
  left: -25%;
}

.small-offset-2 {
  margin-left: 16.66667%;
}

.small-4 {
  width: 33.33333%;
}

.small-push-4 {
  position: relative;
  left: 33.33333%;
}

.small-pull-4 {
  position: relative;
  left: -33.33333%;
}

.small-offset-3 {
  margin-left: 25%;
}

.small-5 {
  width: 41.66667%;
}

.small-push-5 {
  position: relative;
  left: 41.66667%;
}

.small-pull-5 {
  position: relative;
  left: -41.66667%;
}

.small-offset-4 {
  margin-left: 33.33333%;
}

.small-6 {
  width: 50%;
}

.small-push-6 {
  position: relative;
  left: 50%;
}

.small-pull-6 {
  position: relative;
  left: -50%;
}

.small-offset-5 {
  margin-left: 41.66667%;
}

.small-7 {
  width: 58.33333%;
}

.small-push-7 {
  position: relative;
  left: 58.33333%;
}

.small-pull-7 {
  position: relative;
  left: -58.33333%;
}

.small-offset-6 {
  margin-left: 50%;
}

.small-8 {
  width: 66.66667%;
}

.small-push-8 {
  position: relative;
  left: 66.66667%;
}

.small-pull-8 {
  position: relative;
  left: -66.66667%;
}

.small-offset-7 {
  margin-left: 58.33333%;
}

.small-9 {
  width: 75%;
}

.small-push-9 {
  position: relative;
  left: 75%;
}

.small-pull-9 {
  position: relative;
  left: -75%;
}

.small-offset-8 {
  margin-left: 66.66667%;
}

.small-10 {
  width: 83.33333%;
}

.small-push-10 {
  position: relative;
  left: 83.33333%;
}

.small-pull-10 {
  position: relative;
  left: -83.33333%;
}

.small-offset-9 {
  margin-left: 75%;
}

.small-11 {
  width: 91.66667%;
}

.small-push-11 {
  position: relative;
  left: 91.66667%;
}

.small-pull-11 {
  position: relative;
  left: -91.66667%;
}

.small-offset-10 {
  margin-left: 83.33333%;
}

.small-12 {
  width: 100%;
}

.small-offset-11 {
  margin-left: 91.66667%;
}

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%;
}

.small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
  clear: both;
}

.small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
  float: left;
}

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%;
}

.small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
  clear: both;
}

.small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
  float: left;
}

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.33333%;
}

.small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
  clear: both;
}

.small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
  float: left;
}

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%;
}

.small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
  clear: both;
}

.small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
  float: left;
}

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%;
}

.small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
  clear: both;
}

.small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
  float: left;
}

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.66667%;
}

.small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
  clear: both;
}

.small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
  float: left;
}

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.28571%;
}

.small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
  clear: both;
}

.small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
  float: left;
}

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%;
}

.small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
  clear: both;
}

.small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
  float: left;
}

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}

.small-collapse .row {
  margin-right: 0;
  margin-left: 0;
}

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0;
}

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.small-centered {
  margin-right: auto;
  margin-left: auto;
}

.small-centered, .small-centered:last-child:not(:first-child) {
  float: none;
  clear: both;
}

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0;
}

@media print, screen and (min-width: 40em) {
  .medium-1 {
    width: 8.33333%;
  }
  .medium-push-1 {
    position: relative;
    left: 8.33333%;
  }
  .medium-pull-1 {
    position: relative;
    left: -8.33333%;
  }
  .medium-offset-0 {
    margin-left: 0%;
  }
  .medium-2 {
    width: 16.66667%;
  }
  .medium-push-2 {
    position: relative;
    left: 16.66667%;
  }
  .medium-pull-2 {
    position: relative;
    left: -16.66667%;
  }
  .medium-offset-1 {
    margin-left: 8.33333%;
  }
  .medium-3 {
    width: 25%;
  }
  .medium-push-3 {
    position: relative;
    left: 25%;
  }
  .medium-pull-3 {
    position: relative;
    left: -25%;
  }
  .medium-offset-2 {
    margin-left: 16.66667%;
  }
  .medium-4 {
    width: 33.33333%;
  }
  .medium-push-4 {
    position: relative;
    left: 33.33333%;
  }
  .medium-pull-4 {
    position: relative;
    left: -33.33333%;
  }
  .medium-offset-3 {
    margin-left: 25%;
  }
  .medium-5 {
    width: 41.66667%;
  }
  .medium-push-5 {
    position: relative;
    left: 41.66667%;
  }
  .medium-pull-5 {
    position: relative;
    left: -41.66667%;
  }
  .medium-offset-4 {
    margin-left: 33.33333%;
  }
  .medium-6 {
    width: 50%;
  }
  .medium-push-6 {
    position: relative;
    left: 50%;
  }
  .medium-pull-6 {
    position: relative;
    left: -50%;
  }
  .medium-offset-5 {
    margin-left: 41.66667%;
  }
  .medium-7 {
    width: 58.33333%;
  }
  .medium-push-7 {
    position: relative;
    left: 58.33333%;
  }
  .medium-pull-7 {
    position: relative;
    left: -58.33333%;
  }
  .medium-offset-6 {
    margin-left: 50%;
  }
  .medium-8 {
    width: 66.66667%;
  }
  .medium-push-8 {
    position: relative;
    left: 66.66667%;
  }
  .medium-pull-8 {
    position: relative;
    left: -66.66667%;
  }
  .medium-offset-7 {
    margin-left: 58.33333%;
  }
  .medium-9 {
    width: 75%;
  }
  .medium-push-9 {
    position: relative;
    left: 75%;
  }
  .medium-pull-9 {
    position: relative;
    left: -75%;
  }
  .medium-offset-8 {
    margin-left: 66.66667%;
  }
  .medium-10 {
    width: 83.33333%;
  }
  .medium-push-10 {
    position: relative;
    left: 83.33333%;
  }
  .medium-pull-10 {
    position: relative;
    left: -83.33333%;
  }
  .medium-offset-9 {
    margin-left: 75%;
  }
  .medium-11 {
    width: 91.66667%;
  }
  .medium-push-11 {
    position: relative;
    left: 91.66667%;
  }
  .medium-pull-11 {
    position: relative;
    left: -91.66667%;
  }
  .medium-offset-10 {
    margin-left: 83.33333%;
  }
  .medium-12 {
    width: 100%;
  }
  .medium-offset-11 {
    margin-left: 91.66667%;
  }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%;
  }
  .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
    clear: both;
  }
  .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
    float: left;
  }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%;
  }
  .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
    clear: both;
  }
  .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
    float: left;
  }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.33333%;
  }
  .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
    clear: both;
  }
  .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
    float: left;
  }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%;
  }
  .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
    clear: both;
  }
  .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
    float: left;
  }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%;
  }
  .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
    clear: both;
  }
  .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
    float: left;
  }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.66667%;
  }
  .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
    clear: both;
  }
  .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
    float: left;
  }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.28571%;
  }
  .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
    clear: both;
  }
  .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
    float: left;
  }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%;
  }
  .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
    clear: both;
  }
  .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
    float: left;
  }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0;
  }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0;
  }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
  .medium-centered {
    margin-right: auto;
    margin-left: auto;
  }
  .medium-centered, .medium-centered:last-child:not(:first-child) {
    float: none;
    clear: both;
  }
  .medium-uncentered,
.medium-push-0,
.medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0;
  }
}
@media print, screen and (min-width: 64em) {
  .large-1 {
    width: 8.33333%;
  }
  .large-push-1 {
    position: relative;
    left: 8.33333%;
  }
  .large-pull-1 {
    position: relative;
    left: -8.33333%;
  }
  .large-offset-0 {
    margin-left: 0%;
  }
  .large-2 {
    width: 16.66667%;
  }
  .large-push-2 {
    position: relative;
    left: 16.66667%;
  }
  .large-pull-2 {
    position: relative;
    left: -16.66667%;
  }
  .large-offset-1 {
    margin-left: 8.33333%;
  }
  .large-3 {
    width: 25%;
  }
  .large-push-3 {
    position: relative;
    left: 25%;
  }
  .large-pull-3 {
    position: relative;
    left: -25%;
  }
  .large-offset-2 {
    margin-left: 16.66667%;
  }
  .large-4 {
    width: 33.33333%;
  }
  .large-push-4 {
    position: relative;
    left: 33.33333%;
  }
  .large-pull-4 {
    position: relative;
    left: -33.33333%;
  }
  .large-offset-3 {
    margin-left: 25%;
  }
  .large-5 {
    width: 41.66667%;
  }
  .large-push-5 {
    position: relative;
    left: 41.66667%;
  }
  .large-pull-5 {
    position: relative;
    left: -41.66667%;
  }
  .large-offset-4 {
    margin-left: 33.33333%;
  }
  .large-6 {
    width: 50%;
  }
  .large-push-6 {
    position: relative;
    left: 50%;
  }
  .large-pull-6 {
    position: relative;
    left: -50%;
  }
  .large-offset-5 {
    margin-left: 41.66667%;
  }
  .large-7 {
    width: 58.33333%;
  }
  .large-push-7 {
    position: relative;
    left: 58.33333%;
  }
  .large-pull-7 {
    position: relative;
    left: -58.33333%;
  }
  .large-offset-6 {
    margin-left: 50%;
  }
  .large-8 {
    width: 66.66667%;
  }
  .large-push-8 {
    position: relative;
    left: 66.66667%;
  }
  .large-pull-8 {
    position: relative;
    left: -66.66667%;
  }
  .large-offset-7 {
    margin-left: 58.33333%;
  }
  .large-9 {
    width: 75%;
  }
  .large-push-9 {
    position: relative;
    left: 75%;
  }
  .large-pull-9 {
    position: relative;
    left: -75%;
  }
  .large-offset-8 {
    margin-left: 66.66667%;
  }
  .large-10 {
    width: 83.33333%;
  }
  .large-push-10 {
    position: relative;
    left: 83.33333%;
  }
  .large-pull-10 {
    position: relative;
    left: -83.33333%;
  }
  .large-offset-9 {
    margin-left: 75%;
  }
  .large-11 {
    width: 91.66667%;
  }
  .large-push-11 {
    position: relative;
    left: 91.66667%;
  }
  .large-pull-11 {
    position: relative;
    left: -91.66667%;
  }
  .large-offset-10 {
    margin-left: 83.33333%;
  }
  .large-12 {
    width: 100%;
  }
  .large-offset-11 {
    margin-left: 91.66667%;
  }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%;
  }
  .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
    clear: both;
  }
  .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
    float: left;
  }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%;
  }
  .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
    clear: both;
  }
  .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
    float: left;
  }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.33333%;
  }
  .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
    clear: both;
  }
  .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
    float: left;
  }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%;
  }
  .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
    clear: both;
  }
  .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
    float: left;
  }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%;
  }
  .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
    clear: both;
  }
  .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
    float: left;
  }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.66667%;
  }
  .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
    clear: both;
  }
  .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
    float: left;
  }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.28571%;
  }
  .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
    clear: both;
  }
  .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
    float: left;
  }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%;
  }
  .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
    clear: both;
  }
  .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
    float: left;
  }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0;
  }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0;
  }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
  .large-centered {
    margin-right: auto;
    margin-left: auto;
  }
  .large-centered, .large-centered:last-child:not(:first-child) {
    float: none;
    clear: both;
  }
  .large-uncentered,
.large-push-0,
.large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0;
  }
}
.column-block {
  margin-bottom: 1.875rem;
}

.column-block > :last-child {
  margin-bottom: 0;
}

.slide-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.slide-in-down.mui-enter.mui-enter-active {
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.slide-in-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.slide-in-left.mui-enter.mui-enter-active {
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.slide-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.slide-in-up.mui-enter.mui-enter-active {
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.slide-in-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.slide-in-right.mui-enter.mui-enter-active {
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.slide-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateY(0);
  transform: translateY(0);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.slide-out-down.mui-leave.mui-leave-active {
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}

.slide-out-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.slide-out-right.mui-leave.mui-leave-active {
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}

.slide-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateY(0);
  transform: translateY(0);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.slide-out-up.mui-leave.mui-leave-active {
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}

.slide-out-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.slide-out-left.mui-leave.mui-leave-active {
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.fade-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 0;
  transition-property: opacity;
}

.fade-in.mui-enter.mui-enter-active {
  opacity: 1;
}

.fade-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 1;
  transition-property: opacity;
}

.fade-out.mui-leave.mui-leave-active {
  opacity: 0;
}

.hinge-in-from-top.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  -ms-transform-origin: top;
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-top.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  -ms-transform-origin: right;
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-right.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-bottom.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(90deg);
  -ms-transform-origin: bottom;
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(90deg);
  -ms-transform-origin: left;
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-left.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-middle-x.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  -ms-transform-origin: center;
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-middle-y.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  -ms-transform-origin: center;
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-out-from-top.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  -ms-transform-origin: top;
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-top.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0;
}

.hinge-out-from-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  -ms-transform-origin: right;
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-right.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0;
}

.hinge-out-from-bottom.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  -ms-transform-origin: bottom;
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0;
}

.hinge-out-from-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  -ms-transform-origin: left;
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-left.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0;
}

.hinge-out-from-middle-x.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  -ms-transform-origin: center;
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0;
}

.hinge-out-from-middle-y.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  -ms-transform-origin: center;
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0;
}

.scale-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: scale(0.5);
  transform: scale(0.5);
  transition-property: transform, opacity;
  opacity: 0;
}

.scale-in-up.mui-enter.mui-enter-active {
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.scale-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: scale(1.5);
  transform: scale(1.5);
  transition-property: transform, opacity;
  opacity: 0;
}

.scale-in-down.mui-enter.mui-enter-active {
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.scale-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: scale(1);
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1;
}

.scale-out-up.mui-leave.mui-leave-active {
  -ms-transform: scale(1.5);
  transform: scale(1.5);
  opacity: 0;
}

.scale-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: scale(1);
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1;
}

.scale-out-down.mui-leave.mui-leave-active {
  -ms-transform: scale(0.5);
  transform: scale(0.5);
  opacity: 0;
}

.spin-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: rotate(-0.75turn);
  transform: rotate(-0.75turn);
  transition-property: transform, opacity;
  opacity: 0;
}

.spin-in.mui-enter.mui-enter-active {
  -ms-transform: rotate(0);
  transform: rotate(0);
  opacity: 1;
}

.spin-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: rotate(0);
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1;
}

.spin-out.mui-leave.mui-leave-active {
  -ms-transform: rotate(0.75turn);
  transform: rotate(0.75turn);
  opacity: 0;
}

.spin-in-ccw.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: rotate(0.75turn);
  transform: rotate(0.75turn);
  transition-property: transform, opacity;
  opacity: 0;
}

.spin-in-ccw.mui-enter.mui-enter-active {
  -ms-transform: rotate(0);
  transform: rotate(0);
  opacity: 1;
}

.spin-out-ccw.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -ms-transform: rotate(0);
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1;
}

.spin-out-ccw.mui-leave.mui-leave-active {
  -ms-transform: rotate(-0.75turn);
  transform: rotate(-0.75turn);
  opacity: 0;
}

.slow {
  transition-duration: 750ms !important;
}

.fast {
  transition-duration: 250ms !important;
}

.linear {
  transition-timing-function: linear !important;
}

.ease {
  transition-timing-function: ease !important;
}

.ease-in {
  transition-timing-function: ease-in !important;
}

.ease-out {
  transition-timing-function: ease-out !important;
}

.ease-in-out {
  transition-timing-function: ease-in-out !important;
}

.bounce-in {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important;
}

.bounce-out {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important;
}

.bounce-in-out {
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important;
}

.short-delay {
  transition-delay: 300ms !important;
}

.long-delay {
  transition-delay: 700ms !important;
}

.shake {
  animation-name: shake-7;
}

@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%);
  }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%);
  }
}
.spin-cw {
  animation-name: spin-cw-1turn;
}

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(-1turn);
  }
  100% {
    transform: rotate(0);
  }
}
.spin-ccw {
  animation-name: spin-cw-1turn;
}

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(1turn);
  }
}
.wiggle {
  animation-name: wiggle-7deg;
}

@keyframes wiggle-7deg {
  40%, 50%, 60% {
    transform: rotate(7deg);
  }
  35%, 45%, 55%, 65% {
    transform: rotate(-7deg);
  }
  0%, 30%, 70%, 100% {
    transform: rotate(0);
  }
}
.shake,
.spin-cw,
.spin-ccw,
.wiggle {
  animation-duration: 500ms;
}

.infinite {
  animation-iteration-count: infinite;
}

.slow {
  animation-duration: 750ms !important;
}

.fast {
  animation-duration: 250ms !important;
}

.linear {
  animation-timing-function: linear !important;
}

.ease {
  animation-timing-function: ease !important;
}

.ease-in {
  animation-timing-function: ease-in !important;
}

.ease-out {
  animation-timing-function: ease-out !important;
}

.ease-in-out {
  animation-timing-function: ease-in-out !important;
}

.bounce-in {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important;
}

.bounce-out {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important;
}

.bounce-in-out {
  animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important;
}

.short-delay {
  animation-delay: 300ms !important;
}

.long-delay {
  animation-delay: 700ms !important;
}

html {
  scroll-behavior: smooth;
  min-height: 100%;
}

body {
  min-height: 100%;
  background-color: transparent;
  background-color: rgba(0, 0, 0, 0);
  font-family: "Roboto Mono", monospace;
  font-size: 16px;
  color: #FFFFFF;
}

a {
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
  color: #CFCFCF;
  transition: color 0.6s ease;
  cursor: pointer;
}
a:hover, a:active {
  color: #97E657;
}

img {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
  max-width: 100%;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}

h1 {
  font-size: 28.8px;
}
@media (min-width: 640px) {
  h1 {
    font-size: 38.4px;
  }
}

h2 {
  font-size: 22.4px;
}
@media (min-width: 640px) {
  h2 {
    font-size: 32px;
  }
}

h4 {
  text-transform: uppercase;
  font-size: 17.6px;
}
@media (min-width: 640px) {
  h4 {
    font-size: 20.8px;
  }
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.highlight {
  font-weight: 900;
}

button,
.button {
  outline: none;
  display: inline-block;
  margin-top: 20px;
  padding: 16px 20px 18px;
  background-color: #000000;
  color: #CFCFCF;
}

header {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #000000;
}
header nav {
  text-align: center;
}
header nav svg {
  max-width: 40px;
  height: auto;
  fill: #CFCFCF;
  transition: fill 0.6s ease;
  vertical-align: middle;
}
header nav a:hover svg,
header nav a:active svg {
  fill: #97E657;
}
header nav .nav_general {
  margin-bottom: 30px;
}
@media (min-width: 640px) {
  header nav .nav_general {
    margin-bottom: 0;
    text-align: left;
  }
}
header nav .nav_general span:last-child svg {
  max-width: 14px;
}
@media (min-width: 640px) {
  header nav .nav_specific {
    text-align: right;
  }
}

footer {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #000000;
  text-align: center;
}
footer svg {
  height: auto;
  fill: #CFCFCF;
  transition: fill 0.6s ease;
}
footer a:hover svg,
footer a:active svg {
  fill: #97E657;
}
footer .columns:first-child img,
footer .columns:first-child svg {
  max-width: 36px;
}
@media (min-width: 640px) {
  footer .columns:first-child img,
footer .columns:first-child svg {
    max-width: 46px;
  }
}
footer .columns:nth-child(2) img,
footer .columns:nth-child(2) svg {
  max-width: 46px;
}
@media (min-width: 640px) {
  footer .columns:nth-child(2) img,
footer .columns:nth-child(2) svg {
    max-width: 60px;
  }
}
footer .columns:nth-child(3) img,
footer .columns:nth-child(3) svg {
  max-width: 30px;
}
@media (min-width: 640px) {
  footer .columns:nth-child(3) img,
footer .columns:nth-child(3) svg {
    max-width: 40px;
  }
}

body.index h1,
body.index h2 {
  margin: 0;
}
body.index .container {
  padding-top: 100px;
  padding-bottom: 100px;
}
body.index .title {
  text-align: center;
  padding-bottom: 100px;
}
@media (min-width: 640px) {
  body.index .table {
    display: table;
    width: 100%;
  }
}
@media (min-width: 640px) {
  body.index .table_cell {
    display: table-cell;
    width: 50%;
    vertical-align: middle;
  }
  body.index .table_cell:first-child {
    padding-right: 0.9375rem;
  }
  body.index .table_cell:last-child {
    padding-left: 0.9375rem;
  }
}
body.index .container.hero {
  position: relative;
  background-color: #66ADEC;
}
@media (min-width: 768px) {
  body.index .container.hero {
    padding-top: 200px;
    padding-bottom: 200px;
  }
}
@media (min-width: 640px) {
  body.index .container.hero .table .table_cell:first-child {
    vertical-align: bottom;
  }
}
@media (min-width: 768px) {
  body.index .container.hero .table .table_cell:first-child {
    width: 60%;
  }
}
@media (min-width: 640px) {
  body.index .container.hero .table .table_cell:last-child {
    vertical-align: bottom;
  }
}
@media (min-width: 768px) {
  body.index .container.hero .table .table_cell:last-child {
    width: 40%;
  }
}
body.index .container_devices {
  margin-top: 100px;
  text-align: center;
}
@media (min-width: 640px) {
  body.index .container_devices {
    margin-top: 0;
    text-align: right;
  }
}
body.index .container_devices svg {
  fill: #FFFFFF;
}
body.index .container_devices svg:first-child {
  max-width: 26px;
}
@media (min-width: 768px) {
  body.index .container_devices svg:first-child {
    max-width: 32px;
  }
}
body.index .container_devices svg:nth-child(2) {
  max-width: 48px;
}
@media (min-width: 768px) {
  body.index .container_devices svg:nth-child(2) {
    max-width: 54px;
  }
}
body.index .container_devices svg:last-child {
  max-width: 140px;
}
@media (min-width: 768px) {
  body.index .container_devices svg:last-child {
    max-width: 164px;
  }
}
body.index .container.code,
body.index .container.work.two {
  background-color: #fafafa;
}
body.index .container.work.three {
  background-color: #FFFFFF;
}
body.index .container.code {
  color: #000000;
}
body.index .container.code .logos {
  text-align: center;
}
body.index .container.code .logos a {
  display: inline-block;
}
body.index .container.code .logos img {
  max-width: 70px;
}
@media (min-width: 768px) {
  body.index .container.code .logos img {
    max-width: 100px;
  }
}
body.index .container.code .logos .columns:first-child a,
body.index .container.code .logos .columns:nth-child(2) a {
  margin-bottom: 50px;
}
body.index .container.code .logos .columns:first-child img {
  max-width: 180px;
}
@media (min-width: 768px) {
  body.index .container.code .logos .columns:first-child img {
    max-width: 220px;
  }
}
body.index .container.work.one,
body.index .container.work.two,
body.index .container.work.three {
  color: #000000;
}
body.index .container.work.one h2,
body.index .container.work.one h4,
body.index .container.work.one p,
body.index .container.work.two h2,
body.index .container.work.two h4,
body.index .container.work.two p,
body.index .container.work.three h2,
body.index .container.work.three h4,
body.index .container.work.three p {
  margin: 0;
}
body.index .container.work.one h4,
body.index .container.work.two h4,
body.index .container.work.three h4 {
  font-size: 38.4px;
}
@media (min-width: 640px) {
  body.index .container.work.one h4,
body.index .container.work.two h4,
body.index .container.work.three h4 {
    font-size: 64px;
  }
}
body.index .container.work.one .container_work,
body.index .container.work.two .container_work,
body.index .container.work.three .container_work {
  margin-bottom: 60px;
}
@media (min-width: 640px) {
  body.index .container.work.one .row:last-child .container_work,
body.index .container.work.two .row:last-child .container_work,
body.index .container.work.three .row:last-child .container_work {
    margin-bottom: 0;
  }
}
body.index .container.work.one .row:last-child .last .container_work,
body.index .container.work.two .row:last-child .last .container_work,
body.index .container.work.three .row:last-child .last .container_work {
  margin-bottom: 0;
}
@media (min-width: 640px) {
  body.index .container.work.one .row:last-child .last .container_work,
body.index .container.work.two .row:last-child .last .container_work,
body.index .container.work.three .row:last-child .last .container_work {
    margin-bottom: 60px;
  }
}
body.index .container.work.one .work_icon,
body.index .container.work.two .work_icon,
body.index .container.work.three .work_icon {
  padding-top: 10px;
  padding-bottom: 10px;
}
body.index .container.work.one .work_icon h4,
body.index .container.work.two .work_icon h4,
body.index .container.work.three .work_icon h4 {
  font-weight: 900;
}
body.index .container.work.one .work_description p:first-child,
body.index .container.work.two .work_description p:first-child,
body.index .container.work.three .work_description p:first-child {
  font-weight: 900;
}
body.index .container.work.one .work_description p:nth-child(2),
body.index .container.work.two .work_description p:nth-child(2),
body.index .container.work.three .work_description p:nth-child(2) {
  font-style: italic;
}
body.index .container.work.one h2,
body.index .container.work.one h4 {
  color: #66ADEC;
}
body.index .container.work.two h2,
body.index .container.work.two h4 {
  color: #FF8800;
}
body.index .container.work.three h2,
body.index .container.work.three h4 {
  color: #000000;
}
body.index .container.reach_out {
  background-color: #fafafa;
  color: #66ADEC;
}
body.index .container.reach_out .container_resumes {
  text-align: center;
}
body.index .container.reach_out img {
  max-width: 40px;
}
@media (min-width: 640px) {
  body.index .container.reach_out img {
    max-width: 60px;
  }
}
body.index .container.reach_out a {
  font-family: "Rubik", sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  color: #555555;
}
body.index .container.reach_out a:hover, body.index .container.reach_out a:active {
  color: #97E657;
}

body.style_guide h1,
body.style_guide h2,
body.style_guide p {
  margin: 0;
}
body.style_guide .container {
  padding-top: 100px;
  padding-bottom: 100px;
}
body.style_guide .title {
  text-align: center;
  padding-bottom: 100px;
}
body.style_guide .screenshot {
  padding-bottom: 100px;
}
@media (min-width: 640px) {
  body.style_guide .table {
    display: table;
    width: 100%;
  }
}
@media (min-width: 640px) {
  body.style_guide .table_cell {
    display: table-cell;
    width: 50%;
    vertical-align: middle;
  }
  body.style_guide .table_cell:first-child {
    padding-right: 0.9375rem;
  }
  body.style_guide .table_cell:last-child {
    padding-left: 0.9375rem;
  }
}
body.style_guide .container.hero {
  position: relative;
  background-color: #66ADEC;
}
@media (min-width: 768px) {
  body.style_guide .container.hero {
    padding-top: 200px;
    padding-bottom: 200px;
  }
}
@media (min-width: 640px) {
  body.style_guide .container.hero .table .table_cell:first-child {
    width: 80%;
    vertical-align: bottom;
  }
}
@media (min-width: 640px) {
  body.style_guide .container.hero .table .table_cell:last-child {
    width: 20%;
    vertical-align: bottom;
  }
}
body.style_guide .container_devices {
  margin-top: 100px;
  text-align: center;
}
@media (min-width: 640px) {
  body.style_guide .container_devices {
    margin-top: 0;
    text-align: right;
  }
}
body.style_guide .container_devices svg {
  fill: #FFFFFF;
  max-width: 56px;
}
@media (min-width: 768px) {
  body.style_guide .container_devices svg {
    max-width: 62px;
  }
}
body.style_guide .container.fonts {
  background-color: #FFFFFF;
  color: #000000;
  text-align: center;
}
body.style_guide .container.fonts .rubik {
  font-family: "Rubik", sans-serif;
}
body.style_guide .container.fonts .roboto_mono {
  font-family: "Roboto Mono", monospace;
}
body.style_guide .container.fonts p {
  margin-bottom: 60px;
}
body.style_guide .container.fonts .row:last-child .columns:last-child p {
  margin-bottom: 0;
}
@media (min-width: 640px) {
  body.style_guide .container.fonts .row:last-child .columns p {
    margin-bottom: 0;
  }
}
body.style_guide .container.colours {
  background-color: #fafafa;
  color: #000000;
  text-align: center;
}
body.style_guide .container.colours h2 {
  color: #66ADEC;
}
body.style_guide .container.colours p {
  margin-bottom: 60px;
}
@media (min-width: 640px) {
  body.style_guide .container.colours p {
    margin-bottom: 80px;
  }
}
body.style_guide .container.colours .row:last-child .columns:last-child p {
  margin-bottom: 0;
}
@media (min-width: 640px) {
  body.style_guide .container.colours .row:last-child .columns p {
    margin-bottom: 0;
  }
}
body.style_guide .container.colours .black {
  color: #000000;
}
body.style_guide .container.colours .blue {
  color: #66ADEC;
}
body.style_guide .container.colours .green {
  color: #97E657;
}
body.style_guide .container.colours .grey {
  color: #CFCFCF;
}
body.style_guide .container.colours .orange {
  color: #FF8800;
}
body.style_guide .container.colours .white {
  color: #FFFFFF;
}
body.style_guide .container.entities {
  background-color: #FFFFFF;
  color: #000000;
  text-align: center;
}
body.style_guide .container.entities h2 {
  color: #FF8800;
}
body.style_guide .container.entities a {
  color: #000000;
}
body.style_guide .container.entities img {
  max-width: 30px;
  margin-right: 10px;
}
body.style_guide .container.entities img.logo_tcl {
  max-width: 60px;
}
body.style_guide .container.entities .container_entity {
  text-decoration: none;
  display: inline-block;
  margin-bottom: 60px;
  cursor: pointer;
}
@media (min-width: 640px) {
  body.style_guide .container.entities .container_entity {
    margin-bottom: 80px;
  }
}
body.style_guide .container.entities .row:last-child .columns:last-child .container_entity {
  margin-bottom: 0;
}
@media (min-width: 640px) {
  body.style_guide .container.entities .row:last-child .columns .container_entity {
    margin-bottom: 0;
  }
}

body.personal-projects h1,
body.personal-projects h2 {
  margin: 0;
}
body.personal-projects .container {
  padding-top: 100px;
  padding-bottom: 100px;
}
body.personal-projects .title {
  text-align: center;
  padding-bottom: 100px;
}
body.personal-projects .screenshot {
  padding-bottom: 100px;
}
body.personal-projects .screenshot:last-child {
  padding-bottom: 0;
}
body.personal-projects .screenshot.reverse .table .table_cell {
  position: relative;
}
@media (min-width: 640px) {
  body.personal-projects .screenshot.reverse .table .table_cell:first-child {
    position: relative;
    left: 50%;
    padding-left: 0.9375rem;
    padding-right: 0;
  }
}
@media (min-width: 640px) {
  body.personal-projects .screenshot.reverse .table .table_cell:last-child {
    position: relative;
    right: 50%;
    padding-left: 0;
    padding-right: 0.9375rem;
  }
}
body.personal-projects .screenshot a {
  font-family: "Rubik", sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  color: #555555;
}
body.personal-projects .screenshot a:hover, body.personal-projects .screenshot a:active {
  color: #97E657;
}
@media (min-width: 640px) {
  body.personal-projects .table {
    display: table;
    width: 100%;
  }
}
@media (min-width: 640px) {
  body.personal-projects .table_cell {
    display: table-cell;
    width: 50%;
    vertical-align: middle;
  }
  body.personal-projects .table_cell:first-child {
    padding-right: 0.9375rem;
  }
  body.personal-projects .table_cell:last-child {
    padding-left: 0.9375rem;
  }
}
body.personal-projects .container.hero {
  position: relative;
  background-color: #66ADEC;
}
@media (min-width: 768px) {
  body.personal-projects .container.hero {
    padding-top: 200px;
    padding-bottom: 200px;
  }
}
@media (min-width: 640px) {
  body.personal-projects .container.hero .table .table_cell:first-child {
    width: 55%;
    vertical-align: bottom;
  }
}
@media (min-width: 768px) {
  body.personal-projects .container.hero .table .table_cell:first-child {
    width: 75%;
  }
}
@media (min-width: 640px) {
  body.personal-projects .container.hero .table .table_cell:last-child {
    width: 45%;
    vertical-align: bottom;
  }
}
@media (min-width: 768px) {
  body.personal-projects .container.hero .table .table_cell:last-child {
    width: 25%;
  }
}
body.personal-projects .container_devices {
  margin-top: 100px;
  text-align: center;
}
@media (min-width: 640px) {
  body.personal-projects .container_devices {
    margin-top: 0;
    text-align: right;
  }
}
body.personal-projects .container_devices svg {
  fill: #FFFFFF;
  max-width: 140px;
}
@media (min-width: 768px) {
  body.personal-projects .container_devices svg {
    max-width: 164px;
  }
}
body.personal-projects .container.project.one {
  background-color: #fafafa;
  color: #000000;
}
body.personal-projects .container.project.one h4 {
  margin-top: 30px;
  margin-bottom: 0;
  text-transform: uppercase;
}
@media (min-width: 640px) {
  body.personal-projects .container.project.one h4 {
    margin-top: 0;
  }
}
body.personal-projects .container.project.two {
  background-color: #FFFFFF;
  color: #000000;
}
body.personal-projects .container.project.two h2 {
  color: #66ADEC;
}
body.personal-projects .container.project.two h4 {
  margin-top: 30px;
  margin-bottom: 0;
  text-transform: uppercase;
  color: #66ADEC;
}
@media (min-width: 640px) {
  body.personal-projects .container.project.two h4 {
    margin-top: 0;
  }
}
body.personal-projects .container.project.three {
  background-color: #fafafa;
  color: #000000;
}
body.personal-projects .container.project.three h2 {
  color: #FF8800;
}
body.personal-projects .container.project.three h4 {
  margin-top: 30px;
  margin-bottom: 0;
  text-transform: uppercase;
  color: #FF8800;
}
@media (min-width: 640px) {
  body.personal-projects .container.project.three h4 {
    margin-top: 0;
  }
}

body.fourohfour {
  background-color: #66ADEC;
}
body.fourohfour h1 {
  margin: 0;
}
body.fourohfour .container.hero {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #66ADEC;
}
@media (min-width: 768px) {
  body.fourohfour .container.hero {
    padding-top: 200px;
    padding-bottom: 200px;
  }
}